<template>
  <div class="Workspace">
    <v-row class="Workspace__filter-row">
      <v-text-field
          class="item"
          v-on:input="debounceInput"
          outlined
          dense
          v-model="search"
          name="Search"
          label="Search" />
        <v-btn
            v-if="isPrivileged()"
            @click.stop="isCreateDialogOpen = true"
            class="item"
            outlined
            dense
            name="Create"
            label="Create" >
          Create New Workspace
        </v-btn>
    </v-row>
    <v-row v-if="!workspacesLoading">
      <div class="Workspace__listContainer">
        <div class="Workspace__listItem" v-for="workspace in workspaces" :key="workspace.id" @click.stop="goToWorkspace(workspace)">
            <div class="itemSection">
              <span class="title"><v-icon v-if="workspace.private" big class="private"> mdi-lock </v-icon> <span>{{ workspace.name }}</span></span>
            </div>
            <div class="itemSection">
              <span class="description">{{ workspace.description }}</span>
            </div>
            <div class="itemSection" v-if="workspace?.tools?.length > 0">
              <div class="toolList">
                <div class="toolItem" v-for="tool in workspace.tools" :key="tool.id">
                  {{ tool.name }}
                </div>
              </div>
            </div>
            <div class="itemSection">
              <span class="recentActivity">Created: {{ getTime(workspace.createdAt) }}</span>
            </div>
        </div>
      </div>
    </v-row>
    <v-row v-if="workspacesLoading">
      <Loader></Loader>
    </v-row>
    <create-workspace :dialog="isCreateDialogOpen" @close="isCreateDialogOpen = false" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: workspacesGetters, mapActions: workspaceActions } = createNamespacedHelpers('workspace');

import debounce from "lodash/debounce";
import CreateWorkspace from "@/views/ai-workspaces/create-workspace/create-workspace.vue";
import Loader from "@/components/loader/loader.vue";
import * as moment from "moment";
import lodashGet from "lodash/get";

export default {
  name: 'Workspaces',
  components: {Loader, CreateWorkspace },
  data() {
    return {
      search: '',
      isCreateDialogOpen: false,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
      user: 'USER'
    }),
    ...workspacesGetters({
      workspaces: 'WORKSPACES_LIST',
      workspacesLoading: 'WORKSPACE_LIST_LOADING',
    })
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.getWorkspaces({ search: this.search, topLevelAdmin: this.isShAdmin() });
    },
  },
  methods: {
    ...workspaceActions(['getWorkspaces']),
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    getTime(date) {
      return moment(date).fromNow()
    },
    goToWorkspace(item) {
      this.$router.push({ path: `/workspaces/${item.id}` });
    },
    isPrivileged() {
      return this.user.is_privileged
    },
    debounceInput: debounce(function (e) {
      this.search = e;
      this.getWorkspaces({ search: this.search, topLevelAdmin: this.isShAdmin() });
    }, 1000)
  },
  mounted() {
    this.getWorkspaces({ search: this.search });
  },
};
</script>

<style lang="scss" scoped>
@import './ai-workspaces';
</style>
